<template>
  <div class="elv-project-setting-security">
    <div class="elv-project-setting-security-screen">
      <el-popover
        ref="typePopover"
        placement="bottom-start"
        trigger="click"
        popper-class="elv-project-setting-security-screening-popper"
        :show-arrow="false"
        :offset="0.2"
      >
        <template #reference>
          <div class="elv-project-setting-security-screening-button">
            <SvgIcon
              class="elv-project-setting-security-screening-button__icon"
              name="project-setting-cursor"
              width="16"
              height="16"
            />
            <div class="elv-project-setting-security-screening-button__title">
              {{ find(typeOptions, { value: securityParams.type })?.label }}
            </div>
          </div>
        </template>
        <el-scrollbar max-height="384px">
          <li v-for="(item, index) in typeOptions" :key="index" @click="onChangeType(item)">{{ item.label }}</li>
        </el-scrollbar>
      </el-popover>
      <el-popover
        ref="memberPopover"
        placement="bottom-start"
        trigger="click"
        popper-class="elv-project-setting-security-screening-popper"
        :show-arrow="false"
        :offset="0.2"
      >
        <template #reference>
          <div class="elv-project-setting-security-screening-button">
            <SvgIcon
              class="elv-project-setting-security-screening-button__icon"
              name="project-setting-user"
              width="16"
              height="16"
            />
            <div class="elv-project-setting-security-screening-button__title">
              {{ find(memberOptions, { value: securityParams.member })?.label }}
            </div>
          </div>
        </template>
        <el-scrollbar max-height="384px">
          <li v-for="(item, index) in memberOptions" :key="index" @click="onChangeMember(item)">
            <SvgIcon v-if="item?.value === 'ALL'" name="project-setting-user" width="20" height="20" />
            <template v-else>
              <VueBlockies
                v-if="item?.label"
                style="border-radius: 50%"
                :seed="md5UserParams(item?.value)"
                :color="changeAvatarColor(md5UserParams(item?.value))[0]"
                :bgcolor="changeAvatarColor(md5UserParams(item?.value))[1]"
                :size="7"
                :scale="4"
                spot-color="#666"
              />
              <img v-else src="@/assets/img/member-invite-avatar.png" alt="avatar" />
            </template>
            {{ item?.label === '' ? item?.email : item?.label }}
          </li>
        </el-scrollbar>
      </el-popover>
    </div>
    <el-table
      v-loading="tableLoading"
      :data="tableData.list"
      border
      height="calc(100vh - 235px)"
      class="elv-project-setting-security-table"
      header-cell-class-name="elv-project-setting-security-table-header__cell"
      header-row-class-name="elv-project-setting-security-table-header"
      row-class-name="elv-project-setting-security-table-row"
      cell-class-name="elv-project-setting-security-table-row__cell"
    >
      <el-table-column width="150" :label="t('common.dateTime')">
        <template #default="{ row }">
          <div class="elv-project-setting-security-table-row__cell-date">
            {{ row.entity?.operationTime ? dayjs.utc(row.entity?.operationTime).format('YYYY/MM/DD HH:mm:ss') : '-' }}
          </div>
        </template>
      </el-table-column>
      <el-table-column :label="t('common.activity')">
        <template #default="{ row }">
          <div class="elv-project-setting-security-table-row__cell-activity">{{ activityContent(row) }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="address" :label="t('common.member')" width="180">
        <template #default="{ row }">
          <div class="elv-project-setting-security-table-row__cell-member">
            <VueBlockies
              v-if="row?.user?.name"
              style="border-radius: 50%"
              :seed="md5UserParams(row?.user?.userId)"
              :color="changeAvatarColor(md5UserParams(row?.user?.userId))[0]"
              :bgcolor="changeAvatarColor(md5UserParams(row?.user?.userId))[1]"
              :size="7"
              :scale="4"
              spot-color="#666"
            />
            <img v-else src="@/assets/img/member-invite-avatar.png" alt="avatar" />
            <p>{{ row?.user?.name }}</p>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="requestIp" label="IP" min-width="130" width="165" />
      <el-table-column prop="client" :label="t('common.client')" />
    </el-table>
    <el-pagination
      class="elv-project-setting-pagination"
      background
      layout="prev, pager, next"
      :total="tableData.total"
      :hide-on-single-page="true"
      :page-size="securityParams.limit"
      @current-change="onChangePage"
    />
  </div>
</template>

<script lang="ts" setup>
import md5 from 'js-md5'
import dayjs from 'dayjs'
import { find } from 'lodash-es'
import utc from 'dayjs/plugin/utc'
import { useI18n } from 'vue-i18n'
import VueBlockies from 'vue-blockies'
import { ElMessage } from 'element-plus'
import ProjectApi from '@/api/ProjectApi'
import { avatarColor } from '@/config/index'
import { useProjectStore } from '@/stores/modules/project'
import { ProjectSecurityLogType, ProjectSecurityLogItemType, ProjectSecurityLogParamsType } from '#/ProjectTypes'

const props = defineProps({
  width: {
    type: String,
    default: '100%'
  }
})

dayjs.extend(utc)
const { t } = useI18n()
const route = useRoute()
const projectStore = useProjectStore()

const typePopover = ref()
const memberPopover = ref()
const tableLoading = ref(false)
const typeOptions: any = [
  { label: 'All activity', value: 'ALL' },
  { label: 'Login', value: 'LOGIN' },
  { label: 'Source', value: 'SOURCE' },
  { label: 'Transaction', value: 'TRANSACTION' },
  { label: 'Report', value: 'REPORT' },
  { label: 'Contact', value: 'CONTACT' },
  { label: 'Account', value: 'ACCOUNT' },
  { label: 'Automation', value: 'AUTOMATION' }
]

const securityParams = reactive({
  page: 1,
  limit: 20,
  type: 'ALL',
  member: 'ALL'
})
const typeEnum: any = {
  LOGIN: 'LOGIN',
  SOURCE: ['CREATE_SOURCE', 'UPDATE_SOURCE', 'DELETE_SOURCE', 'UPLOAD_CSV'],
  TRANSACTION: [
    'REVIEW_ACTIVITY',
    'BATCH_REVIEW_ACTIVITY',
    'REVIEW_ACTIVITY_ALL',
    'UPDATE_ACTIVITY',
    'BATCH_UPDATE_ACTIVITY',
    'EXPORT_ACTIVITY_LIST'
  ],
  REPORT: ['REGENERATE_REPORT'],
  CONTACT: ['ADD_CONTACT', 'BATCH_ADD_CONTACT', 'UPDATE_CONTACT', 'DELETE_CONTACT', 'BATCH_DELETE_CONTACT'],
  ACCOUNT: ['CREATE_ENTITY_ACCOUNT', 'DELETE_ENTITY_ACCOUNT', 'BATCH_DELETE_ENTITY_ACCOUNT', 'UPDATE_ENTITY_ACCOUNT'],
  AUTOMATION: ['CREATE_CONFIG_RULE', 'UPDATE_CONFIG_RULE', 'DELETE_CONFIG_RULE', 'GENERATE_ACTIVITY']
}

const tableData: ProjectSecurityLogType = reactive({
  list: [],
  total: 0
})

const memberOptions = computed(() => {
  let list: any = [{ label: 'All member', value: 'ALL' }]
  if (projectStore.projectMemberList.length) {
    list = list.concat(
      projectStore.projectMemberList
        .filter((i) => i.name !== '')
        .map((item: any) => ({ label: item.name, value: item.userId, email: item.email }))
    )
  }
  return list
})

const projectId = computed(() => {
  return Number(route.params?.projectId)
})

const md5UserParams = computed(() => {
  return (userId: number) => {
    return md5(String(userId)) + String(userId)
  }
})

const activityContent = computed(() => {
  return (item: ProjectSecurityLogItemType) => {
    let content = ''
    switch (item.type) {
      case 'CREATE_SOURCE':
        content = t('log.addSource', { sourceName: item.params?.sourceName })
        break
      case 'UPDATE_SOURCE':
        content = t('log.editSource', { sourceName: item.params?.sourceName })
        break
      case 'DELETE_SOURCE':
        content = t('log.deleteSource', { sourceName: item.params?.sourceName })
        break
      case 'UPLOAD_CSV':
        content = t('log.uploadCsv', { fileName: item.params?.fileName })
        break
      case 'REVIEW_ACTIVITY':
        content = t('log.reviewTransaction', { activityId: item.params?.activityIds?.[0] })
        break
      case 'BATCH_REVIEW_ACTIVITY':
        content = t('log.batchReviewTransaction', {
          activityIds:
            item.params?.activityIds.length > 3
              ? `${item.params?.activityIds?.slice(0, 3)?.join(', ')}...`
              : item.params?.activityIds?.join(', '),
          total: item.params?.activityIds?.length
        })
        break
      case 'REVIEW_ACTIVITY_ALL':
        content = t('log.batchReviewTransaction', {
          activityIds:
            item.params?.activityIds.length > 3
              ? `${item.params?.activityIds?.slice(0, 3)?.join(', ')}...`
              : item.params?.activityIds?.join(', '),
          total: item.params?.activityIds?.length
        })
        break
      case 'UPDATE_ACTIVITY':
        content = t('log.editTransactionDetail', { activityId: item.params?.activityIds?.[0] })
        break
      case 'BATCH_UPDATE_ACTIVITY':
        content = t('log.batchTransactionDetail', {
          activityIds:
            item.params?.activityIds.length > 3
              ? `${item.params?.activityIds?.slice(0, 3)?.join(', ')}...`
              : item.params?.activityIds?.join(', '),
          total: item.params?.activityIds?.length
        })
        break
      case 'REGENERATE_REPORT':
        content = t('log.regenerateReports')
        break
      case 'ADD_CONTACT':
        content = t('log.addContact', { contactName: item.params?.name })
        break
      case 'BATCH_ADD_CONTACT':
        // eslint-disable-next-line no-case-declarations
        const addContactNameList = item.params.map((i: any) => i.name)
        content = t('log.batchAddContact', {
          contactNames:
            addContactNameList.length > 3
              ? `${addContactNameList?.slice(0, 3)?.join(', ')}...`
              : addContactNameList?.join(', '),
          total: addContactNameList?.length
        })
        break
      case 'UPDATE_CONTACT':
        content = t('log.editContact', { contactName: item.params?.name })
        break
      case 'DELETE_CONTACT':
        content = t('log.deleteContact', { contactName: item.params?.name })
        break
      case 'BATCH_DELETE_CONTACT':
        // eslint-disable-next-line no-case-declarations
        const contactNameList = item.params.map((i: any) => i.name)
        content = t('log.batchDeleteContact', {
          contactNames:
            contactNameList.length > 3 ? `${contactNameList?.slice(0, 3)?.join(', ')}...` : contactNameList?.join(', '),
          total: contactNameList?.length
        })
        break
      case 'CREATE_CONFIG_RULE':
        content = t('log.addRule', { ruleName: item.params?.name })
        break
      case 'UPDATE_CONFIG_RULE':
        content = t('log.editRule', { ruleName: item.params?.name })
        break
      case 'DELETE_CONFIG_RULE':
        content = t('log.deleteRule', { ruleName: item.params?.name })
        break
      case 'GENERATE_ACTIVITY':
        content = t('log.manuallyExecuteRules')
        break
      case 'EXPORT_ACTIVITY_LIST':
        content = ''
        break
      case 'CREATE_ENTITY_ACCOUNT':
        content = t('log.addAccount', { accountName: item.params?.name })
        break
      case 'UPDATE_ENTITY_ACCOUNT':
        content = t('log.editAccount', { accountName: item.params?.name })
        break
      case 'DELETE_ENTITY_ACCOUNT':
        content = t('log.deleteAccount', { accountName: item.params?.name })
        break
      case 'BATCH_DELETE_ENTITY_ACCOUNT':
        // eslint-disable-next-line no-case-declarations
        const accountNameList = item.params.map((i: any) => i.name)
        content = t('log.batchDeleteAccount', {
          accountNames:
            accountNameList.length > 3 ? `${accountNameList?.slice(0, 3)?.join(', ')}...` : accountNameList?.join(', '),
          total: accountNameList?.length
        })
        break
      default:
        content = t('button.signIn')
        break
    }
    return content
  }
})

const changeAvatarColor = (val: string) => {
  const lastStr = val.substr(val.length - 1, 1)
  return avatarColor[lastStr]
}

const fetchProjectSecurityLog = async () => {
  try {
    tableLoading.value = true
    const params: ProjectSecurityLogParamsType = {
      page: securityParams.page,
      limit: securityParams.limit
    }
    if (securityParams.type !== 'ALL') {
      params.types = typeEnum[securityParams.type]
    }
    if (securityParams.member !== 'ALL') {
      params.userId = Number(securityParams.member)
    }
    const { data } = await ProjectApi.getProjectLog(projectId.value, params)
    tableData.list = data.list
    tableData.total = data.total
  } catch (error: any) {
    console.log(error)
    ElMessage.error(error.message)
  } finally {
    tableLoading.value = false
  }
}

const onChangeMember = (item: any) => {
  securityParams.member = item.value
  securityParams.page = 1
  memberPopover.value?.hide()
  fetchProjectSecurityLog()
}

const onChangeType = (item: any) => {
  securityParams.type = item.value
  securityParams.page = 1
  typePopover.value?.hide()
  fetchProjectSecurityLog()
}

const onChangePage = (page: number) => {
  securityParams.page = page
  fetchProjectSecurityLog()
}

onMounted(() => {
  fetchProjectSecurityLog()
})
</script>
<style lang="scss">
.elv-project-setting-security {
  width: v-bind('props.width');
}

.elv-project-setting-security-table {
  --el-table-border-color: #d4dce5;
  --el-table-border: 1px solid #edf0f3;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #d4dce6;
  border-right: 0px;
  border-left: 0px;
  border-bottom-width: 1px;

  .el-table__inner-wrapper {
    width: 100%;

    &::after,
    &::before {
      width: 0;
      height: 0;
    }
  }

  &.el-table--border {
    border-bottom-color: #edf0f3;

    &::after,
    &::before {
      width: 0;
      height: 0;
    }
  }

  .el-table__body-wrapper {
    height: 616px;

    .el-scrollbar__view {
      width: 100%;
      margin-bottom: 0px !important;
    }
  }

  .el-table__border-left-patch {
    width: 0;
  }

  .elv-project-setting-security-table-header {
    background: #eef4fb;

    .elv-project-setting-security-table-header__cell {
      background: #eef4fb;
      height: 36px;
      box-sizing: border-box;
      border-right-color: #e4e7eb;
      border-bottom-color: #ced7e0;

      &:last-child {
        border-right: 0px;
      }

      .cell {
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        color: #666d74;
        padding: 0 10px;
        display: flex;
        align-items: center;
        justify-items: center;
      }
    }
  }

  // .elv-project-setting-security-table-row:last-child {
  //   .elv-project-setting-security-table-row__cell {
  //     border-bottom: 0px;
  //   }
  // }

  .elv-project-setting-security-table-row__cell {
    padding: 0;
    height: 42px;

    &:last-child {
      border-right: 0px;
    }

    .cell {
      padding: 0 10px;
      font-family: 'Plus Jakarta Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      color: #0e0f11;
      line-height: 16px;
    }

    .elv-project-setting-security-table-row__cell-activity {
      font-weight: 500;
    }

    .elv-project-setting-security-table-row__cell-member {
      display: flex;
      align-items: center;

      img {
        width: 20px;
        height: 20px;
        background: #f6f6f6;
        border-radius: 50%;
        display: block;
        margin-right: 8px;
      }
    }

    .elv-project-setting-security-table-row__cell-date {
      display: flex;
      align-items: center;
      font-family: 'Barlow';
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
      color: #0e0f11;
    }
  }
}

.elv-project-setting-pagination {
  margin-top: 12px;
  justify-content: flex-end;
  margin-right: 24px;

  &.el-pagination.is-background {
    .el-pager {
      li {
        height: 26px;
        min-width: 26px;
        border: 1px solid #edf0f3;
        background-color: #fff;
        font-family: 'Barlow';
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        border-radius: 3px;
        color: #1e2024;

        &:not(.is-active):hover {
          color: #1753eb;
          border-color: #7596eb;
        }

        &:not(.is-active):active {
          border: 0px;
          color: #fff;
          font-weight: 700;
          background-color: #1343bf;
        }

        &.is-active {
          border: 0px;
          color: #fff;
          font-weight: 700;
          background-color: #1753eb;
        }

        &.more {
          border: 0px;
        }
      }
    }

    .btn-prev,
    .btn-next {
      width: 46px;
      height: 26px;
      padding: 6px 16px 6px 16px;
      border-radius: 3px;
      background-color: #fff;
      border: 1px solid #edf0f3;

      &:hover {
        color: #1753eb;
        border-color: #7596eb;

        svg {
          fill: #1753eb;
        }
      }

      &:disabled {
        color: #a8abb2;
        background-color: #f5f7fa;
        border-color: #edf0f3;

        svg {
          fill: #a8abb2;
        }
      }
    }
  }
}

.elv-project-setting-security-popper {
  .el-select-dropdown__list {
    padding: 0;
  }

  .el-select-dropdown__item {
    height: 40px;
    display: flex;
    align-items: center;
    padding-left: 12px;
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #0e0f11;

    img {
      width: 20px;
      height: 20px;
      display: block;
      margin-right: 10px;
    }

    p {
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        width: fit-content;
        height: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        padding: 2px 4px 2px 4px;
        border-radius: 3px;
        border: 1px solid #dde1e6;
        margin-left: 10px;
        font-size: 11px;
        color: #d0d4d9;
      }
    }

    &.hover {
      background: #f9fafb;
    }
  }
}

.elv-project-setting-security-screen {
  display: flex;
  padding: 7px 20px;

  .elv-project-setting-security-screening-button {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 9px;
    height: 30px;
    border: 1px solid #edf0f3;
    border-radius: 16px;
    cursor: pointer;
    font-family: 'Plus Jakarta Sans';
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    margin-right: 8px;
    transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);

    &.is-disabled {
      cursor: not-allowed;
    }

    .elv-project-setting-security-screening-button__icon {
      fill: #96a4ca;
      margin-right: 4px;
    }

    &:not(.is-disabled):hover,
    &:not(.is-disabled):active {
      border: 1px solid #5e85eb;

      .elv-project-setting-security-screening-button__icon {
        fill: #5e85eb;
      }
    }
  }
}

.el-popover.elv-project-setting-security-screening-popper {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  min-width: 190px;
  width: auto !important;
  background: #ffffff;
  border: 1px solid #e4e7eb;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.15);
  border-radius: 4px;

  .el-scrollbar__view,
  .el-scrollbar {
    width: 100%;
  }

  li {
    width: 100%;
    height: 40px;
    box-sizing: border-box;
    padding: 0px 12px;
    display: flex;
    align-items: center;
    font-family: 'Plus Jakarta Sans';
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    cursor: pointer;
    color: #0e0f11;

    &:hover {
      background: #f9fafb;
    }

    img {
      width: 20px;
      height: 20px;
      display: block;
      margin-right: 10px;
    }

    svg {
      margin-right: 10px;
      fill: #5e85eb;
    }
  }
}
</style>
