<template>
  <el-scrollbar
    height="352"
    view-class="elv-project-permission-view"
    class="elv-project-permission-container"
    wrap-class="elv-project-permission-wrap"
  >
    <div class="elv-project-permission-info-list">
      <div v-for="(item, index) in projectStore?.projectEntityList" :key="index">
        <div class="elv-project-permission-item__info">
          <img v-if="item.logo" :src="item.logo" :alt="item.name" />
          <img v-else src="@/assets/img/reports/default-entity-logo.png" alt="entity" />
          <span>{{ item.name }}</span>
        </div>
      </div>
    </div>

    <div class="elv-project-permission-list">
      <div v-for="(item, i) in projectStore?.projectEntityList" :key="i" class="elv-project-permission-item">
        <!-- <div class="elv-project-permission-item__info">
          <img :src="item.logo" alt="" />
          <span>{{ item.name }}</span>
        </div> -->
        <div class="elv-project-permission-level-1">
          <el-checkbox
            v-model="permissionCheckStatus[i].dataSourceCheckAll"
            :label="t('report.source')"
            :indeterminate="permissionCheckStatus[i].dataSourceIsIndeterminate"
            @change="onCheckPermissionClassifyAllChange('dataSource', i, $event)"
          />
        </div>
        <div class="elv-project-permission-level-2">
          <el-checkbox
            v-model="permissionData[i].dataSource.view"
            @change="onCheckedPermissionChange('dataSource', i)"
            >{{ t('common.viewListPermission', { operating: t('report.source') }) }}</el-checkbox
          >
          <el-checkbox
            v-model="permissionData[i].dataSource.create"
            @change="onCheckedPermissionChange('dataSource', i)"
            >{{ t('common.addNewPermission', { operating: t('report.source') }) }}</el-checkbox
          >
          <el-checkbox
            v-model="permissionData[i].dataSource.update"
            @change="onCheckedPermissionChange('dataSource', i)"
            >{{ t('common.editPermission', { operating: t('report.source') }) }}</el-checkbox
          >
          <el-checkbox
            v-model="permissionData[i].dataSource.delete"
            @change="onCheckedPermissionChange('dataSource', i)"
            >{{ t('common.deletePermission', { operating: t('report.source') }) }}</el-checkbox
          >
        </div>
        <div class="elv-project-permission-level-1">
          <el-checkbox
            v-model="permissionCheckStatus[i].entityAccountCheckAll"
            :label="t('common.account')"
            :indeterminate="permissionCheckStatus[i].entityAccountIsIndeterminate"
            @change="onCheckPermissionClassifyAllChange('entityAccount', i, $event)"
          />
        </div>
        <div class="elv-project-permission-level-2">
          <el-checkbox
            v-model="permissionData[i].entityAccount.view"
            @change="onCheckedPermissionChange('entityAccount', i)"
            >{{ t('common.viewListPermission', { operating: t('common.account') }) }}</el-checkbox
          >
          <el-checkbox
            v-model="permissionData[i].entityAccount.create"
            @change="onCheckedPermissionChange('entityAccount', i)"
            >{{ t('common.addNewPermission', { operating: t('common.account') }) }}</el-checkbox
          >
          <el-checkbox
            v-model="permissionData[i].entityAccount.update"
            @change="onCheckedPermissionChange('entityAccount', i)"
            >{{ t('common.editPermission', { operating: t('common.account') }) }}</el-checkbox
          >
          <el-checkbox
            v-model="permissionData[i].entityAccount.delete"
            @change="onCheckedPermissionChange('entityAccount', i)"
            >{{ t('common.deletePermission', { operating: t('common.account') }) }}</el-checkbox
          >
        </div>
        <div class="elv-project-permission-level-1">
          <el-checkbox
            v-model="permissionCheckStatus[i].contactCheckAll"
            :label="t('common.contact')"
            :indeterminate="permissionCheckStatus.contactIsIndeterminate"
            @change="onCheckPermissionClassifyAllChange('contact', i, $event)"
          />
        </div>
        <div class="elv-project-permission-level-2">
          <el-checkbox v-model="permissionData[i].contact.view" @change="onCheckedPermissionChange('contact', i)">{{
            t('common.viewListPermission', { operating: t('common.contact') })
          }}</el-checkbox>
          <el-checkbox v-model="permissionData[i].contact.create" @change="onCheckedPermissionChange('contact', i)">{{
            t('common.addNewPermission', { operating: t('common.contact') })
          }}</el-checkbox>
          <el-checkbox v-model="permissionData[i].contact.update" @change="onCheckedPermissionChange('contact', i)">{{
            t('common.editPermission', { operating: t('common.contact') })
          }}</el-checkbox>
          <el-checkbox v-model="permissionData[i].contact.delete" @change="onCheckedPermissionChange('contact', i)">{{
            t('common.deletePermission', { operating: t('common.contact') })
          }}</el-checkbox>
        </div>
        <div class="elv-project-permission-level-1">
          <el-checkbox
            v-model="permissionCheckStatus[i].reportCheckAll"
            :label="t('common.report')"
            :indeterminate="permissionCheckStatus[i].reportIsIndeterminate"
            @change="onCheckPermissionClassifyAllChange('report', i, $event)"
          />
        </div>
        <div class="elv-project-permission-level-2">
          <el-checkbox v-model="permissionData[i].report.view" @change="onCheckedPermissionChange('report', i)">{{
            t('common.viewListPermission', { operating: t('common.report') })
          }}</el-checkbox>
          <el-checkbox v-model="permissionData[i].report.generate" @change="onCheckedPermissionChange('report', i)">{{
            t('common.generatePermission', { operating: t('common.report') })
          }}</el-checkbox>
        </div>
        <div class="elv-project-permission-level-1">
          <el-checkbox
            v-model="permissionCheckStatus[i].transactionCheckAll"
            label="Transaction"
            :indeterminate="permissionCheckStatus[i].transactionIsIndeterminate"
            @change="onCheckPermissionClassifyAllChange('transaction', i, $event)"
          />
        </div>
        <div class="elv-project-permission-level-2">
          <el-checkbox
            v-model="permissionData[i].transaction.viewList"
            @change="onCheckedPermissionChange('transaction', i)"
            >{{ t('common.viewListPermission', { operating: t('report.transaction') }) }}</el-checkbox
          >
          <el-checkbox
            v-model="permissionData[i].transaction.viewDetail"
            @change="onCheckedPermissionChange('transaction', i)"
            >{{ t('common.viewDetailPermission', { operating: t('report.transaction') }) }}</el-checkbox
          >
          <el-checkbox
            v-model="permissionData[i].transaction.update"
            @change="onCheckedPermissionChange('transaction', i)"
            >{{ t('common.editPermission', { operating: t('report.transaction') }) }}</el-checkbox
          >
          <el-checkbox
            v-model="permissionData[i].transaction.export"
            @change="onCheckedPermissionChange('transaction', i)"
            >{{ t('common.exportPermission', { operating: t('report.transaction') }) }}</el-checkbox
          >
          <el-checkbox
            v-model="permissionData[i].transaction.review"
            @change="onCheckedPermissionChange('transaction', i)"
            >{{ t('common.reviewPermission', { operating: t('report.transaction') }) }}</el-checkbox
          >
          <el-checkbox
            v-model="permissionData[i].transaction.erp"
            @change="onCheckedPermissionChange('transaction', i)"
            >{{ t('common.syncToErp', { operating: t('report.transaction') }) }}</el-checkbox
          >
        </div>
        <div class="elv-project-permission-level-1">
          <el-checkbox
            v-model="permissionCheckStatus[i].generalLedgerCheckAll"
            :label="t('project.nav.generalLedger')"
            :indeterminate="permissionCheckStatus[i].generalLedgerIsIndeterminate"
            @change="onCheckPermissionClassifyAllChange('generalLedger', i, $event)"
          />
        </div>
        <div class="elv-project-permission-level-2">
          <el-checkbox
            v-model="permissionData[i].generalLedger.view"
            @change="onCheckedPermissionChange('generalLedger', i)"
            >{{ t('common.viewListPermission', { operating: t('project.nav.generalLedger') }) }}</el-checkbox
          >
        </div>
        <div class="elv-project-permission-level-1">
          <el-checkbox
            v-model="permissionCheckStatus[i].resolutionCenterCheckAll"
            :label="t('report.resolutionCenter')"
            :indeterminate="permissionCheckStatus[i].resolutionCenterIsIndeterminate"
            @change="onCheckPermissionClassifyAllChange('resolutionCenter', i, $event)"
          />
        </div>
        <div class="elv-project-permission-level-2">
          <el-checkbox
            v-model="permissionData[i].resolutionCenter.view"
            @change="onCheckedPermissionChange('resolutionCenter', i)"
            >{{ t('common.viewListPermission', { operating: t('report.resolutionCenter') }) }}</el-checkbox
          >
        </div>
        <div class="elv-project-permission-level-1">
          <el-checkbox
            v-model="permissionCheckStatus[i].configRuleCheckAll"
            :label="t('common.rule')"
            :indeterminate="permissionCheckStatus[i].configRuleIsIndeterminate"
            @change="onCheckPermissionClassifyAllChange('configRule', i, $event)"
          />
        </div>
        <div class="elv-project-permission-level-2">
          <el-checkbox
            v-model="permissionData[i].configRule.view"
            @change="onCheckedPermissionChange('configRule', i)"
            >{{ t('common.viewListPermission', { operating: t('common.rule') }) }}</el-checkbox
          >
          <el-checkbox
            v-model="permissionData[i].configRule.create"
            @change="onCheckedPermissionChange('configRule', i)"
            >{{ t('common.addNewPermission', { operating: t('common.rule') }) }}</el-checkbox
          >
          <el-checkbox
            v-model="permissionData[i].configRule.update"
            @change="onCheckedPermissionChange('configRule', i)"
            >{{ t('common.editPermission', { operating: t('common.rule') }) }}</el-checkbox
          >
          <el-checkbox
            v-model="permissionData[i].configRule.delete"
            @change="onCheckedPermissionChange('configRule', i)"
            >{{ t('common.deletePermission', { operating: t('common.rule') }) }}</el-checkbox
          >
        </div>
      </div>
    </div>
  </el-scrollbar>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { isEmpty, throttle } from 'lodash-es'
import { useProjectStore } from '@/stores/modules/project'

const props = defineProps({
  model: {
    type: String,
    default: 'add'
  },
  currentPermissions: {
    type: Array,
    default: () => {
      return []
    }
  }
})

const { t } = useI18n()
const projectStore = useProjectStore()

const permissionCheckStatus: any = ref([])
const permissionData: any = ref([])

const onCheckPermissionClassifyAllChange = (type: string, index: number, val: any) => {
  if (val) {
    permissionCheckStatus.value[index][`${type}CheckAll`] = true
    Object.keys(permissionData.value[index][type])
    permissionData.value[index][type] = Object.keys(permissionData.value[index][type]).reduce((obj: any, key) => {
      // eslint-disable-next-line no-param-reassign
      obj[key] = true
      return obj
    }, {})
  } else {
    permissionCheckStatus.value[index][`${type}CheckAll`] = false
    permissionData.value[index][type] = Object.keys(permissionData.value[index][type]).reduce((obj: any, key) => {
      // eslint-disable-next-line no-param-reassign
      obj[key] = false
      return obj
    }, {})
  }
  permissionCheckStatus.value[index][`${type}IsIndeterminate`] = false
}

const onCheckedPermissionChange = (type: string, index: number) => {
  permissionCheckStatus.value[index][`${type}CheckAll`] = Object.values(permissionData.value[index][type]).every(
    (val) => val === true
  )
  permissionCheckStatus.value[index][`${type}IsIndeterminate`] =
    Object.values(permissionData.value[index][type]).some((val) => val === true) &&
    !permissionCheckStatus.value[index][`${type}CheckAll`]
  permissionCheckStatus.value[index].checkAll = Object.values(permissionData.value[index]).every((obj: any) => {
    return Object.values(obj).every((val) => val === true)
  })
}

const rightScroll = () => {
  const top = document.getElementsByClassName('elv-project-permission-wrap')[0].scrollTop
  document.getElementsByClassName('elv-project-member-permission-wrap')[0].scrollTop = top
}

defineExpose({ permissionData })

watch(
  () => props.currentPermissions,
  () => {
    if (props.model === 'add') {
      permissionCheckStatus.value = projectStore?.projectEntityList.map(() => {
        return {
          dataSourceCheckAll: false,
          contactCheckAll: false,
          reportCheckAll: false,
          entityAccountCheckAll: false,
          transactionCheckAll: false,
          configRuleCheckAll: false,
          generalLedgerCheckAll: false,
          resolutionCenterCheckAll: false,
          dataSourceIsIndeterminate: false,
          contactIsIndeterminate: false,
          reportIsIndeterminate: false,
          entityAccountIsIndeterminate: false,
          transactionIsIndeterminate: false,
          configRuleIsIndeterminate: false,
          generalLedgerIsIndeterminate: false,
          resolutionCenterIsIndeterminate: false
        }
      })
      permissionData.value = projectStore?.projectEntityList.map(() => {
        return {
          dataSource: {
            view: false,
            create: false,
            update: false,
            delete: false
          },
          contact: {
            view: false,
            create: false,
            update: false,
            delete: false
          },
          transaction: {
            viewList: false,
            viewDetail: false,
            update: false,
            export: false,
            review: false,
            erp: false
          },
          report: {
            view: false,
            generate: false
          },
          entityAccount: {
            view: false,
            create: false,
            update: false,
            delete: false
          },
          configRule: {
            view: false,
            create: false,
            update: false,
            delete: false
          },
          generalLedger: {
            view: false
          },
          resolutionCenter: {
            view: false
          }
        }
      })
    } else {
      permissionData.value = projectStore?.projectEntityList.map((i: any) => {
        const currentPermission: any = props.currentPermissions.find((current: any) => {
          return current.entityId === i.entityId
        })
        if (isEmpty(currentPermission) || !currentPermission) {
          return {
            dataSource: {
              view: false,
              create: false,
              update: false,
              delete: false
            },
            contact: {
              view: false,
              create: false,
              update: false,
              delete: false
            },
            transaction: {
              viewList: false,
              viewDetail: false,
              update: false,
              export: false,
              review: false,
              erp: false
            },
            report: {
              view: false,
              generate: false
            },
            entityAccount: {
              view: false,
              create: false,
              update: false,
              delete: false
            },
            configRule: {
              view: false,
              create: false,
              update: false,
              delete: false
            },
            generalLedger: {
              view: false
            },
            resolutionCenter: {
              view: false
            }
          }
        }
        console.log(currentPermission?.permission)
        return currentPermission?.permission
      })

      permissionCheckStatus.value = projectStore?.projectEntityList.map((i: any) => {
        const currentPermission: any = props.currentPermissions.find((current: any) => {
          return current.entityId === i.entityId
        })
        if (isEmpty(currentPermission) || !currentPermission) {
          return {
            dataSourceCheckAll: false,
            contactCheckAll: false,
            reportCheckAll: false,
            entityAccountCheckAll: false,
            transactionCheckAll: false,
            configRuleCheckAll: false,
            generalLedgerCheckAll: false,
            resolutionCenterCheckAll: false,
            dataSourceIsIndeterminate: false,
            contactIsIndeterminate: false,
            reportIsIndeterminate: false,
            entityAccountIsIndeterminate: false,
            transactionIsIndeterminate: false,
            configRuleIsIndeterminate: false,
            generalLedgerIsIndeterminate: false,
            resolutionCenterIsIndeterminate: false
          }
        }
        const checkStatus: any = {}
        Object.keys(currentPermission?.permission).forEach((item: any) => {
          checkStatus[`${item}CheckAll`] = Object.values(currentPermission?.permission[item]).every(
            (val) => val === true
          )
          checkStatus[`${item}IsIndeterminate`] =
            Object.values(currentPermission?.permission[item]).some((val) => val === true) &&
            !checkStatus[`${item}CheckAll`]
        })
        return checkStatus
      })
    }
  },
  { immediate: true }
)

onBeforeMount(() => {})

onMounted(() => {
  nextTick(() => {
    document.getElementsByClassName('elv-project-permission-wrap')[0]?.addEventListener('scroll', throttle(rightScroll))
  })
})

onBeforeUnmount(() => {
  document.getElementsByClassName('elv-project-permission-wrap')[0]?.removeEventListener('scroll', rightScroll)
})
</script>

<style lang="scss" scoped>
.elv-project-permission-container {
  position: absolute;
  top: 0px;
  right: 0;
  // padding-top: 26px;
}

:deep(.elv-project-permission-wrap) {
  width: 300px;
}

:deep(.elv-project-permission-view) {
  max-width: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .elv-project-permission-info-list {
    display: flex;
    justify-content: flex-end;
    height: 18px;
    margin-bottom: 8px;

    > div {
      .elv-project-permission-item__info {
        width: 100px;
        display: flex;
        align-items: stretch;
        justify-content: flex-end;

        img {
          width: 16px;
          height: 16px;
          display: block;
          margin-right: 4px;
        }

        span {
          font-family: 'Plus Jakarta Sans';
          font-size: 11px;
          font-weight: 400;
          line-height: 14px;
          color: #000000;
          word-break: break-word;
        }
      }
    }
  }

  .elv-project-permission-list {
    display: flex;
    justify-content: flex-end;
  }

  .elv-project-permission-item {
    width: 100px;

    .elv-project-permission-level-1,
    .elv-project-permission-level-2 {
      border: 0px;
      background-color: transparent;

      .el-checkbox {
        border: 0px;
      }
    }
  }

  .el-checkbox__label {
    display: none !important;
  }
}
</style>
