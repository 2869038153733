<template>
  <div class="elv-project-settings-general-content">
    <el-form-item
      v-if="projectStore.projectDetail?.plan !== 'FREE' && projectStore.projectDetail?.plan !== 'BASIC'"
      :label="t('report.projectName')"
      class="elv-project-settings-general-projectName"
    >
      <el-input v-model="projectName" minlength="1" maxlength="64" placeholder="" />
    </el-form-item>
    <div class="elv-project-settings-general-header">
      <p>{{ t('common.entities') }}({{ projectStore?.projectEntityList?.length }})</p>
      <elv-button
        v-if="
          (projectStore.projectDetail?.plan === 'PRO' || projectStore.projectDetail?.plan === 'ENTERPRISE') &&
          projectStore.projectDetail?.usedEntityCount < projectStore.projectDetail?.entityCount
        "
        round
        plain
        width="119"
        height="32"
        @click="onAddEntity"
      >
        <SvgIcon name="source-add" width="16" height="16" />{{ t('button.addEntity') }}
      </elv-button>
      <p v-else class="elv-project-settings-general-header__upgrade">
        {{ t('message.needMoreEntities') }}<span @click="onJumpPrice">{{ t('message.upgradePro') }}</span>
      </p>
    </div>
    <ul class="elv-project-settings-general-list">
      <li v-for="(entity, index) in projectStore?.projectEntityList" :key="index">
        <div class="elv-project-settings-general-item__user">
          <img v-if="entity.logo" :src="entity.logo" alt="logo" :onerror="useDefaultImage" />
          <img v-else src="@/assets/img/reports/default-entity-logo.png" alt="logo" :onerror="useDefaultImage" />
          <div class="elv-project-settings-general-item__info">
            <p class="elv-project-settings-general-item__info-name">{{ entity.name }}</p>
            <p class="elv-project-settings-general-item__info-email">
              {{ t('project.nav.sources') }}: {{ utils.formatNumber(entity.usedSourceCount) }},{{
                t('project.nav.transactions')
              }}: {{ utils.formatNumber(entity.usedTxCount) }}
            </p>
          </div>
        </div>
        <div class="elv-project-settings-general-item__operating">
          <div class="elv-project-settings-general-item__operating--button">
            <SvgIcon name="project-setting" width="18" height="18" @click="onEditEntity(entity)" />
            <SvgIcon
              v-if="projectStore.projectDetail?.plan !== 'FREE' && projectStore.projectDetail?.plan !== 'BASIC'"
              name="sources-delete"
              width="18"
              height="18"
              @click="onDeleteEntity(entity)"
            />
          </div>
        </div>
      </li>
    </ul>

    <elv-button
      v-if="projectStore.projectDetail?.plan !== 'FREE' && projectStore.projectDetail?.plan !== 'BASIC'"
      class="elv-project-settings-general-save"
      round
      height="44"
      width="156"
      :loading="saveLoading"
      @click="onSaveChangeName"
      >{{ t('button.saveChanges') }}</elv-button
    >
  </div>

  <MessageBox
    ref="deleteEntityRef"
    :confirm-button-text="t('button.delete')"
    :cancel-button-text="t('button.cancel')"
    :title="t('common.deleteEntity')"
    :loading="deleteMemberLoading"
    @onConfirmEvent="onConfirmDeleteEntity"
    @onCancelEvent="onCloseConfirm"
  >
    <template #content>
      <span class="elv-delete-member-confirm-header__title">{{
        t('message.deleteEntityMessage', { name: currentEntityData?.name })
      }}</span></template
    >
  </MessageBox>

  <EntityDialog ref="entityDialogRef" :model="model" :current-data="currentEntityData" />
</template>

<script setup lang="ts">
// import _ from 'lodash-es'
import utils from '@/lib/utils'
import { useI18n } from 'vue-i18n'
import { ElMessage } from 'element-plus'
import ProjectApi from '@/api/ProjectApi'
import EntityDialog from './EntityDialog.vue'
import MessageBox from '@/components/MessageBox.vue'
import { useProjectStore } from '@/stores/modules/project'
import { useGlobalStore } from '@/stores/modules/global'
import { useReportStore } from '@/stores/modules/reports/index'
import defaultImg from '@/assets/img/reports/default-entity-logo.png'

const { t } = useI18n()
const route = useRoute()
const router = useRouter()
const reportStore = useReportStore()
const globalStore = useGlobalStore()
const projectStore = useProjectStore()

const model = ref('add')
const projectName = ref('')
const entityDialogRef = ref()
const deleteEntityRef = ref()
const saveLoading = ref(false)
const deleteMemberLoading = ref(false)
const currentEntityData = ref()

const projectId = computed(() => {
  return Number(route.params?.projectId)
})

const onConfirmDeleteEntity = async () => {
  try {
    deleteMemberLoading.value = true
    await ProjectApi.deleteProjectEntity(currentEntityData.value?.entityId)
    ElMessage.success(t('message.deleteSuccess'))
    globalStore.fetchProjectList()
    projectStore.fetchEntityList(projectId.value)
    projectStore.fetchProjectDetail(projectId.value)
    deleteEntityRef.value?.onCheckMessageBoxDialog()
    currentEntityData.value = {}
  } catch (error: any) {
    console.log(error)
    currentEntityData.value = {}
    ElMessage.error(error?.message)
  } finally {
    deleteMemberLoading.value = false
  }
}

const useDefaultImage = (event: any) => {
  event.target.src = defaultImg
}

const onJumpPrice = () => {
  window.open('https://www.elven.com/pricing', '_blank')
}

const onCloseConfirm = () => {
  deleteEntityRef.value?.onCheckMessageBoxDialog()
}

const onSaveChangeName = async () => {
  try {
    if (projectName.value === projectStore.projectDetail?.name) return
    saveLoading.value = true
    await ProjectApi.editProjectName(projectId.value, { name: projectName.value })
    saveLoading.value = false
    ElMessage.success(t('message.saveSuccess'))
    projectStore.fetchProjectDetail(projectId.value)
    globalStore.fetchProjectList()
  } catch (error: any) {
    saveLoading.value = false
    ElMessage.error(error?.message)
  }
}

const onEditEntity = async (entityData: any) => {
  model.value = 'edit'
  // entityDialogRef.value?.onCheckEntityDialog()
  router.push({ name: 'entity-settings', params: { projectId: projectId.value, entityId: entityData.entityId } })
  await reportStore.fetchEntityDetail(entityData.entityId)
  currentEntityData.value = reportStore.entityDetail
}

const onAddEntity = () => {
  currentEntityData.value = {}
  model.value = 'add'
  entityDialogRef.value?.onCheckEntityDialog()
}

const onDeleteEntity = (entityData: any) => {
  currentEntityData.value = entityData
  deleteEntityRef.value?.onCheckMessageBoxDialog()
}

watch(
  () => projectStore.projectDetail,
  () => {
    projectName.value = projectStore.projectDetail?.name
  },
  { immediate: true }
)
</script>

<style lang="scss" scoped>
.elv-project-settings-general-projectName {
  :deep(.el-input) {
    width: 572px;
    height: 44px;
    border-radius: 4px;

    &.is-disabled {
      background: #f9fafb;

      .el-input__wrapper {
        background: #f9fafb;
        border: 1px solid #dde1e6;
        box-shadow: none;
        filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.08));

        .el-input__suffix {
          svg,
          i {
            width: 20px;
            height: 20px;
            fill: #d0d4d9;
          }
        }
      }

      .el-input__inner {
        color: #0e0f11;
        -webkit-text-fill-color: #0e0f11;
      }
    }
  }

  :deep(.el-input__inner) {
    font-family: 'Plus Jakarta Sans';
    font-weight: 400;
    font-size: 14px;
    color: #0e0f11;
  }

  :deep(.el-input__wrapper) {
    width: -webkit-fill-available;
    height: -webkit-fill-available;
    border-radius: 4px;
    border: 1px solid #dde1e6;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
    padding: 1px 12px;
    transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);

    .el-input__suffix {
      svg,
      i {
        width: 20px;
        height: 20px;
        fill: #838d95;
      }
    }
  }

  :deep(.el-input:not(.is-disabled) .el-input__wrapper) {
    &:hover {
      border: 1px solid #7596eb !important;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
    }

    &.is_focus {
      border: 1px solid #7596eb !important;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
    }
  }

  &.el-form-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;

    &:last-of-type {
      margin-bottom: 0px;
    }

    &.is-error {
      .el-input__wrapper {
        background: #faeee6;
        border: 1px solid #7e4a15;
        box-shadow: none;
      }

      .el-input__inner {
        color: #7e4a15;
      }

      .el-form-item__error {
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: #7e4a15;
      }
    }

    :deep(.el-form-item__label) {
      font-family: 'Plus Jakarta Sans';
      font-weight: 600;
      font-size: 13px;
      line-height: 16px;
      color: #636b75;
      justify-content: flex-start;
    }
  }
}

.elv-delete-member-confirm-header__title {
  font-family: 'Plus Jakarta Sans';
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #1e2024;
}

.elv-project-settings-general-content {
  width: 572px;

  .elv-project-settings-general-header {
    width: 100%;
    height: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    p {
      font-family: 'Plus Jakarta Sans';
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #0e0f11;
    }

    .elv-project-settings-general-header__upgrade {
      color: #636b75;
      font-size: 12px;
      line-height: auto;
      font-weight: 400;

      span {
        color: #1343bf;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

  .elv-project-settings-general-list {
    width: 100%;
    border-bottom: 1px solid #edf0f3;
    border-top: 1px solid #edf0f3;

    li {
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 68px;
      border-bottom: 1px solid #edf0f3;

      &:last-child {
        border-bottom: none;
      }

      .elv-project-settings-general-item__user {
        display: flex;
        align-items: center;

        img {
          width: 36px;
          height: 36px;
          display: block;
        }
      }

      .elv-project-settings-general-item__info {
        margin-left: 8px;
        display: flex;
        flex-direction: column;

        .elv-project-settings-general-item__info-name {
          font-family: 'Plus Jakarta Sans';
          font-weight: 700;
          font-size: 14px;
          line-height: 18px;
          color: #0e0f11;
        }

        .elv-project-settings-general-item__info-email {
          font-family: 'Plus Jakarta Sans';
          font-weight: 400;
          font-size: 13px;
          line-height: 16px;
          color: #838d95;
        }
      }

      .elv-project-settings-general-item__operating {
        display: flex;
        align-items: center;

        .elv-project-settings-general-item__operating--button {
          display: flex;
          align-items: center;

          svg {
            fill: #838d95;
            cursor: pointer;
            transition: all 0.1s;

            &:last-child {
              margin-left: 16px;
            }

            &:hover {
              fill: #1e2024;
            }
          }
        }

        .elv-project-settings-general-item__operating--role {
          box-sizing: border-box;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100px;
          height: 24px;
          border: 1px solid #dde1e6;
          border-radius: 2px;
          font-family: 'Plus Jakarta Sans';
          font-weight: 400;
          font-size: 13px;
          line-height: 16px;
          color: #838d95;
        }
      }
    }
  }

  .elv-project-settings-general-save {
    margin-top: 16px;
  }
}
</style>
