<template>
  <div v-loading.lock="projectStore.loaded" class="elv-project-settings-container">
    <DetailHeader class="elv-project-setting-info" type="project" />
    <div class="elv-project-settings-header">
      <div class="elv-project-settings-header__title">
        {{ t('common.projectSettings') }}
      </div>
      <el-tabs v-model="currentMenu" class="elv-report-sources-tab" @tab-click="onCheckMenu">
        <el-tab-pane :label="t('common.general')" name="general" class="elv-report-detail-pane"> </el-tab-pane>
        <el-tab-pane :label="t('common.members')" name="members" class="elv-report-detail-pane"> </el-tab-pane>
        <el-tab-pane :label="t('common.PlanBilling')" name="plan" class="elv-report-detail-pane"> </el-tab-pane>
        <el-tab-pane :label="t('common.security')" name="security" class="elv-report-detail-pane"> </el-tab-pane>
      </el-tabs>
    </div>
    <div v-if="currentMenu !== 'security'" class="elv-project-settings-content">
      <el-scrollbar class="elv-project-settings-general-content">
        <General v-if="currentMenu === 'general'" />

        <Members v-if="currentMenu === 'members'" />

        <Subscription v-if="currentMenu === 'plan'" />
      </el-scrollbar>
    </div>
    <Security v-else :width="headerWidth" />
  </div>
</template>

<script setup lang="ts">
import utils from '@/lib/utils'
import { useI18n } from 'vue-i18n'
import General from './components/General.vue'
import Members from './components/Members.vue'
import Security from './components/Security.vue'
import Subscription from './components/Subscription.vue'
import DetailHeader from '../components/DetailHeader.vue'
import { useProjectStore } from '@/stores/modules/project'
import { useMenuGlobalStore } from '@/stores/modules/global/menu'

const { t } = useI18n()
const route = useRoute()
const projectStore = useProjectStore()
const menuGlobalStore = useMenuGlobalStore()

const currentMenu = ref('general')

const projectId = computed(() => {
  return Number(route.params?.projectId)
})

const headerWidth = computed(() => {
  return utils.isMobile() || !menuGlobalStore.isFixedMenu ? 'calc(100vw - 48px)' : 'calc(100vw - 179px)'
})

const onCheckMenu = (menu: any) => {
  if (currentMenu.value === menu.props.name) return
  currentMenu.value = menu.props.name
}

watch(
  () => route.params,
  async () => {
    if (route.params?.projectId === undefined || route.name !== 'project-settings') return
    try {
      currentMenu.value = 'general'
      await projectStore.projectInfoInit(projectId.value)
    } catch (error) {
      console.log(error)
    }
  },
  { immediate: true }
)
</script>

<style lang="scss" scoped>
.elv-project-settings-container {
  width: 100%;
  position: relative;

  .elv-project-setting-info {
    width: 100%;
    box-sizing: border-box;
    padding-left: 20px;
    border-bottom: 1px solid #dde1e6;
    height: 48px;
  }

  .elv-project-settings-header {
    width: 100%;

    .elv-project-settings-header__title {
      margin: 16px 22px;
      font-family: 'Plus Jakarta Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 25px;
      color: #0e0f11;
    }

    svg {
      fill: #1e2024;
      position: absolute;
      // right: -108px;
      right: 40px;
      top: 4px;
      cursor: pointer;
    }
  }

  :deep(.elv-report-sources-tab) {
    .el-tabs__header {
      width: v-bind('headerWidth');
      margin-top: 8px;
      margin-bottom: 0px;
    }

    .el-tabs__nav-wrap:after {
      height: 1px !important;
    }

    .el-tabs__nav.is-top {
      padding-left: 20px !important;
    }

    .el-tabs__active-bar {
      background-color: $elv-color-1343BF;
    }

    .el-tabs__item.is-active {
      color: $elv-color-394048;
    }

    .el-tabs__item {
      color: $elv-color-858B92;
      font-family: 'Plus Jakarta Sans';
      font-weight: 700;
      font-size: 13px;
      padding: 0;
      padding-left: 24px;

      &:nth-child(2) {
        padding-left: 0px;
      }
    }
  }

  .elv-project-settings-content {
    height: calc(100vh - 135px);
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    padding-top: 32px;
  }

  :deep(.el-scrollbar__view) {
    width: 700px;
  }
}
</style>

<style>
.elv-layout-main {
  padding: 0 !important;
}
</style>
