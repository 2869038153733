<template>
  <div class="elv-project-settings-subscription-content">
    <div v-if="showErrorInfo" class="elv-project-settings-subscription-error">
      <img src="@/assets/img/reports/table-warning-filled.png" alt="error" />
      <p>
        {{
          currentEntityData?.subscribeStatus !== 'NORMAL'
            ? t('message.subscriptionExpired')
            : t('message.subscriptionLimitError')
        }}
      </p>
    </div>
    <span class="elv-project-settings-subscription-plan" :class="[planClass]">
      {{ capitalize(currentEntityData?.plan) }}
    </span>
    <div class="elv-project-settings-subscription-dosage">
      <div class="elv-project-settings-subscription-dosage-info">
        <div class="elv-project-settings-subscription-dosage-info__item">
          <p>{{ t('project.nav.transactions') }}</p>
          <p :class="{ 'is-error': currentEntityData?.usedTxCount > currentEntityData?.txCount }">
            {{ utils.formatNumber(currentEntityData?.usedTxCount)
            }}<span>/{{ utils.formatNumber(currentEntityData?.txCount) }}</span>
          </p>
        </div>
        <div class="elv-project-settings-subscription-dosage-info__item">
          <p>{{ t('project.nav.sources') }}</p>
          <p :class="{ 'is-error': currentEntityData?.usedSourceCount > currentEntityData?.dataSourceCount }">
            {{ utils.formatNumber(currentEntityData?.usedSourceCount)
            }}<span>/{{ utils.formatNumber(currentEntityData?.dataSourceCount) }}</span>
          </p>
        </div>
        <div class="elv-project-settings-subscription-dosage-info__item">
          <p>{{ t('common.entities') }}</p>
          <p :class="{ 'is-error': currentEntityData?.usedEntityCount > currentEntityData?.entityCount }">
            {{ utils.formatNumber(currentEntityData?.usedEntityCount)
            }}<span>/{{ utils.formatNumber(currentEntityData?.entityCount) }}</span>
          </p>
        </div>
        <div class="elv-project-settings-subscription-dosage-info__item">
          <p>{{ t('common.members') }}</p>
          <p :class="{ 'is-error': currentEntityData?.usedUserCount > currentEntityData?.userCount }">
            {{ utils.formatNumber(currentEntityData?.usedUserCount)
            }}<span>/{{ utils.formatNumber(currentEntityData?.userCount) }}</span>
          </p>
        </div>
      </div>
      <el-button width="91" hight="32" @click="onJumpPrice"
        ><SvgIcon name="upgrade-rocket" width="16" height="16" />{{ t('button.upgrade') }}</el-button
      >
    </div>
    <div class="elv-project-settings-subscription-title">{{ t('common.subscription') }}</div>
    <div class="elv-project-settings-subscription-item">
      <p>{{ t('common.subscriptionStatus') }}</p>
      <span>{{ currentEntityData?.subscribeStatus === 'NORMAL' ? t('common.active') : t('common.expired') }}</span>
    </div>
    <div class="elv-project-settings-subscription-item">
      <p>{{ t('common.startDate') }}</p>
      <span>{{ dayjs(currentEntityData?.createdAt).format('YYYY-MM-DD') }}</span>
    </div>
    <div class="elv-project-settings-subscription-item">
      <p>{{ t('common.expirationDate') }}</p>
      <span>{{ currentEntityData?.expiredDate }}</span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { capitalize } from 'lodash-es'
import dayjs from 'dayjs'
import utils from '@/lib/utils'
import { useI18n } from 'vue-i18n'
import { useProjectStore } from '@/stores/modules/project'

const { t } = useI18n()
const projectStore = useProjectStore()

const currentEntityData = computed(() => {
  return projectStore.projectDetail
})

const planClass = computed(() => {
  return currentEntityData.value?.plan.toLowerCase()
})
const showErrorInfo = computed(() => {
  return (
    currentEntityData.value?.usedTxCount > currentEntityData.value?.txCount ||
    currentEntityData.value?.usedSourceCount > currentEntityData.value?.dataSourceCount ||
    currentEntityData.value?.usedEntityCount > currentEntityData.value?.entityCount ||
    currentEntityData.value?.usedUserCount > currentEntityData.value?.userCount ||
    currentEntityData.value?.subscribeStatus !== 'NORMAL'
  )
})

const onJumpPrice = () => {
  window.open('https://www.elven.com/pricing', '_blank')
}
</script>

<style lang="scss" scoped>
.elv-project-settings-subscription-content {
  width: 700px;

  .elv-project-settings-subscription-error {
    width: 100%;
    display: flex;
    padding: 10px 8px;
    height: 38px;
    box-sizing: border-box;
    align-items: center;
    border: 1px solid #abc0f5;
    background-color: rgba(229, 237, 255, 0.2);
    border-radius: 2px;
    margin-bottom: 24px;

    img {
      width: 16px;
      height: 16px;
      display: block;
      margin-right: 8px;
    }

    p {
      color: #1e2024;
      font-family: 'Plus Jakarta Sans';
      font-size: 13px;
      font-weight: 500;
    }
  }

  .elv-project-settings-subscription-plan {
    font-family: 'Plus Jakarta Sans';
    font-weight: 700;
    font-size: 28px;
    line-height: 140%;
    color: #0e0f11;

    &.free {
      leading-trim: both;
      text-edge: cap;
      letter-spacing: -0.03em;
      background: linear-gradient(95.91deg, #00d32f -2.64%, #05a31f 27.09%, #c4eccc 105.06%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
    }

    &.basic {
      leading-trim: both;
      text-edge: cap;
      letter-spacing: -0.03em;
      background: linear-gradient(95.91deg, #d3a500 -2.64%, #ff730e 27.09%, #ffb88b 105.06%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
    }

    &.pro {
      leading-trim: both;
      text-edge: cap;
      letter-spacing: -0.03em;
      background: linear-gradient(101.06deg, #1351f0 -1.56%, #1a47e8 28.16%, #0085ff 106.1%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
    }

    &.enterprise {
      leading-trim: both;
      text-edge: cap;
      letter-spacing: -0.03em;
      background: linear-gradient(101.06deg, #f02013 -1.56%, #e81a1a 28.16%, #ff774b 106.1%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
    }
  }

  .elv-project-settings-subscription-dosage {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 22px;
    margin-bottom: 36px;

    .elv-project-settings-subscription-dosage-info {
      display: flex;

      .elv-project-settings-subscription-dosage-info__item {
        margin-right: 50px;

        &:last-child {
          margin-right: 0;
        }

        > p {
          color: #1e2024;
          font-size: 12px;
          font-style: normal;
          line-height: 15px;
          margin-bottom: 9px;
        }

        p {
          color: #0e0f11;
          font-size: 14px;
          font-family: 'Plus Jakarta Sans';
          font-style: normal;
          font-weight: 400;
          line-height: 18px;

          &.is-error {
            font-weight: 600;
            color: #eb3a30;
          }

          span {
            color: #838d95 !important;
          }
        }
      }
    }

    :deep(.el-button) {
      padding: 8px;
      color: #1e2024;
      font-size: 12px;
      font-family: 'Plus Jakarta Sans';
      font-weight: 500;

      svg {
        fill: #1e2024;
        margin-right: 8px;
      }

      &:hover,
      &:focus,
      &:active {
        color: #1343bf;
        border-color: #7596eb;
        background-color: #fff;

        svg {
          fill: #1343bf;
        }
      }
    }
  }

  .elv-project-settings-subscription-title {
    font-family: 'Plus Jakarta Sans';
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #0e0f11;
    width: 100%;
    margin-bottom: 16px;
  }

  .elv-project-settings-subscription-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    width: 700px;
    height: 50px;
    color: #0e0f11;
    border-bottom: 1px solid #edf0f3;

    &:first-child {
      border-top: 1px solid #edf0f3;
    }

    p {
      font-family: 'Plus Jakarta Sans';
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: #0e0f11;
    }

    span {
      font-weight: 700;
    }
  }
}
</style>
